<template>
    <div id="Stages">
        <b-container>
            <h2>Наш прайс лист дешевых перевозок</h2>
            <b-card no-body>
                <b-tabs pills card vertical>
                    <b-tab v-for="(stages, index) in Stages" :title="stages.stage +'. '+ stages.name ">
                        <b-card-text>
                            <h3>Тариф {{stages.stage}}</h3>
                            <h4>{{stages.name}}</h4>
                            <ol>
                                <li v-for="(feature,index) in stages.list" :key="index">{{feature}}</li>
                            </ol>
                        </b-card-text>
                    </b-tab>
                </b-tabs>
            </b-card>
        </b-container>
    </div>
</template>

<script>
    export default {
        name: "Stages",
        data(){
            return{
                Stages:[
                    {
                        name:'Тариф-1',
                        stage:'1',
                        list:[
                            '3-х метровый кузов',
                            'Грузоподъемность 1 тонна',
                            '12 куб.м',
                            'Минимальный заказ 4 часа',
                            '5000/1000/30',
                            '1000₽ последующий час',
                            '30₽/км за МКАД в круг',
                        ]
                    },
                    {
                        name:'Тариф-2',
                        stage:'2',
                        list:[
                            '4.2 метровый кузов',
                            '1-2 тонны',
                            '12-20 м.куб.',
                            '8 евро паллет',
                            '5500/1100/35',
                            'Минимальный заказ 4 часа',
                        ]
                    },
                    {
                        name:'Тариф-3',
                        stage:'3',
                        list:[
                            '5.2 метровый кузов',
                            'До  2.5 тонны',
                            '20-25 м.куб.',
                            'До 10 евро паллет',
                            '7200/1200/40',
                            'Минимальный заказ 4 часа',
                        ]
                    },
                    {
                        name:'Тариф-4',
                        stage:'4',
                        list:[
                            '6 метровый кузов',
                            '2.5-3 тонны',
                            '20-25 м.куб.',
                            'До 12 евро паллет',
                            '8500/1300/45',
                            'Минимальный заказ 5 часа',
                        ]
                    },
                ]
            }
        }
    }
</script>

<style scoped>

</style>