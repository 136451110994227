
<!-- <template>
    <div id="Calculation">
        <b-container>
            <h2>Расчет стоимости доставки груза</h2>
            <!-- <template>
                <form class="flex-container" ref="form" @submit.prevent="sendEmail">
                    <label>Name</label>
                    <input type="text" name="user_name">
                    <label>Email</label>
                    <input type="email" name="user_email">
                    <label>Message</label>
                    <textarea name="message"></textarea>
                    <input type="submit" value="Send">
                </form>
            </template> --/>

            <!-- <form class="flex-container">
                <div><b-form-input autocomplete="off" v-model="formCalculation.departure" type="text" placeholder="Пункт отправления"/></div>
                <div><b-form-input autocomplete="off" v-model="formCalculation.arrival" type="text" placeholder="Пункт назначения"/></div>
                <div><b-form-input autocomplete="off" v-model="formCalculation.name" type="text" placeholder="Наименование"/></div>
                <div><b-form-input autocomplete="off" v-model="formCalculation.fio" type="text" placeholder="ФИО"/></div>
                <div><b-form-input autocomplete="off" v-model="formCalculation.phone" type="tel" v-mask="'+7 (###) ###-##-##'" placeholder="+7(9**)***-**-**"/></div>
                <div><b-form-input autocomplete="off" v-model="formCalculation.email" type="email" placeholder="Email"/></div>
                <div><b-form-input autocomplete="off" v-model="formCalculation.volume" type="number" placeholder="Объем груза, м3"/></div>
                <div><b-form-input autocomplete="off" v-model="formCalculation.weight" type="number" placeholder="Вес груза, кг"/></div>
                <div><b-form-checkbox v-model="formCalculation.insurance" name="check-button" switch><p>Страховка</p></b-form-checkbox></div>
                <div><b-form-checkbox v-model="formCalculation.package" name="check-button" switch><p>Упаковка</p></b-form-checkbox></div>
            </form> -->
            <b-button>ОТПРАВИТЬ ЗАЯВКУ  <component :is="svg"/></b-button>
        </b-container>
    </div>
</template>

<script>
import emailjs from '@emailjs/browser';

export default {
  methods: {
    sendEmail() {
      emailjs
        .sendForm('service_lgr263o', 'template_07z3mch', this.$refs.form, {
          publicKey: 'Ud_JW6SQ9ZRYNCVXe',
        })
        .then(
          () => {
            console.log('SUCCESS!');
            console.log('message send.')
          },
          (error) => {
            console.log('FAILED...', error.text);
          },
        );
    },
  },
};
</script>

<script>
    import arrow from './svg/arrow';
    export default {
        name: "Calculation",
        data(){
            return{
                svg:arrow,
                formCalculation:{
                    departure:'',
                    arrival:'',
                    name: '',
                    fio:'',
                    phone: '',
                    email:'',
                    volume:'',
                    weight:'',
                    insurance: false,
                    package: false,
                },
            }
        }
    }
</script>

<style scoped>

</style>

--/>